// polyfills.ts
// Polyfill for Object.hasOwn (for environments that don't support ES2022)
if (!Object.hasOwn) {
  Object.hasOwn = function (obj, prop) {
    return Object.prototype.hasOwnProperty.call(obj, prop);
  };
}

// Polyfill for Array.prototype.at
if (!Array.prototype.at) {
  Array.prototype.at = function (index) {
    // Convert the index to an integer
    index = Math.trunc(index) || 0;

    // If the index is negative, count from the end
    if (index < 0) {
      index += this.length;
    }

    // If the index is out of bounds, return undefined
    if (index < 0 || index >= this.length) {
      return undefined;
    }

    // Return the element at the given index
    return this[index];
  };
}

// Polyfill for String.prototype.at
if (!String.prototype.at) {
  String.prototype.at = function (index) {
    // Convert the index to an integer
    index = Math.trunc(index) || 0;

    // If the index is negative, count from the end
    if (index < 0) {
      index += this.length;
    }

    // If the index is out of bounds, return undefined
    if (index < 0 || index >= this.length) {
      return undefined;
    }

    // Return the character at the given index
    return this.charAt(index);
  };
}
// polyfill for Array.prototype.findLast
if (!Array.prototype.findLast) {
  Object.defineProperty(Array.prototype, 'findLast', {
    value: function (callback, thisArg) {
      if (this == null) {
        throw new TypeError('Array.prototype.findLast called on null or undefined');
      }
      if (typeof callback !== 'function') {
        throw new TypeError(`${callback} is not a function`);
      }

      for (let i = this.length - 1; i >= 0; i--) {
        if (callback.call(thisArg, this[i], i, this)) {
          return this[i];
        }
      }
      return undefined;
    },
    configurable: true,
    writable: true,
  });
}
