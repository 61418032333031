import Combobox from '@/components/combobox';
import { Label } from '@/components/ui/label';
import Password from './password';
import OAuth from './oauth';
import Saml from './saml';
import { useEffect, useState } from 'react';
import { AppSettings, AuthMethodRow } from '@/types/writeback';
import Pincode from './pincode';
import TableauAuth from './tableauAuth';
import { generateRandomString } from '@/utils/functional';
import { setupPolling } from '@/writeback/api';
import { useWBEStore } from '../../../../writeback/store';
import NoAuth from './noAuth';
import { EventBus } from '@/utils/event-bus';

const Login = ({
  possibleAuthMethods,
  appSettings,
}: {
  possibleAuthMethods: AuthMethodRow[];
  appSettings: AppSettings | null;
}) => {
  const [selectedMethod, setSelectedMethod] = useState<AuthMethodRow | null>(null);
  const [loading, setLoading] = useState(false);

  const url = useWBEStore((state) => state.url);
  const refreshToken = useWBEStore((state) => state.refreshToken);

  const startAuthentication = async (key: string, isOauth: boolean) => {
    const uid = generateRandomString(48);
    const baseUrl = isOauth ? `${url}/api/oauth/${key}` : `${url}/api/auth/${key}/login`;
    const ssoWindow = window.open(`${baseUrl}?ref=desktop&uid=${uid}&referer=extension`, '_blank');
    if (ssoWindow) {
      setLoading(true);
      await setupPolling(uid, url, (loading) => setLoading(loading), refreshToken);
      EventBus.triggerEvent('updateGrid');
    } else {
      console.warn('InputTables is unable to open SAML window');
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!selectedMethod && possibleAuthMethods.length === 1) {
      setSelectedMethod(possibleAuthMethods[0]);
    }
  }, [selectedMethod, possibleAuthMethods]);

  const renderAuthComponent = () => {
    if (!selectedMethod) return null;

    switch (selectedMethod.value) {
      case 'username_password':
        return <Password />;
      case 'oauth':
        return (
          <OAuth
            oauthMethods={appSettings?.settings.oauth}
            startAuthentication={startAuthentication}
            loading={loading}
            setLoading={(loading) => setLoading(loading)}
          />
        );
      case 'saml':
        return (
          <Saml
            samlSettings={appSettings?.settings.saml}
            startAuthentication={startAuthentication}
            loading={loading}
            setLoading={(loading) => setLoading(loading)}
          />
        );
      case 'pincode':
        return <Pincode />;
      case 'tableau_rest_api':
        return <TableauAuth />;
      case 'none':
        return <NoAuth />;
      default:
        return null;
    }
  };

  return (
    <div>
      {possibleAuthMethods.length > 1 && (
        <div>
          <Label className="">Choose login method</Label>
          <Combobox
            options={possibleAuthMethods}
            placeholder="Select login option"
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            onChange={(option) => {
              setSelectedMethod(option);
            }}
            value={selectedMethod}
          />
        </div>
      )}
      {renderAuthComponent()}
    </div>
  );
};

export default Login;
